import moment from 'moment'

const getAgeByBirthdayDate = (birthdayDate: string | undefined) => {
  try {
    if (!birthdayDate) {
      return ''
    }
    if (birthdayDate?.length === 10) {
      const totalAge = moment().diff(
        moment(birthdayDate, 'DD/MM/YYYY'),
        'years'
      )

      if (totalAge < 0 || isNaN(totalAge)) return 0
      return totalAge
    }
    return moment().diff(
      moment(new Date(birthdayDate).toLocaleDateString('pt-br'), 'DD/MM/YYYY'),
      'years'
    )
  } catch (err) {
    return ''
  }
}

export default getAgeByBirthdayDate
