import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteLoadSurgicalPendencyDocument
  implements LoadSurgicalPendencyDocument
{
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}
  load(
    surgical_order_id: number,
    document_id: number,
    group_id?: string
  ): Promise<LoadSurgicalPendencyDocument.Model> {
    return this.surgicalPendencyRepository.loadSurgicalPendencyDocument(
      surgical_order_id,
      document_id,
      group_id
    )
  }
}
