import React, { HTMLAttributes } from 'react'
import * as S from './styles'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/right-arrow.svg'
import { ReactComponent as LeftArrow } from 'presentation/assets/icons/left-arrow.svg'
import 'react-calendar/dist/Calendar.css'

import CalendarComponent, { CalendarProps } from 'react-calendar'

type Props = {
  range?: boolean
  onDateChange?: (cb: Date | Date[]) => void
} & HTMLAttributes<HTMLDivElement> &
  CalendarProps

const Calendar = ({ range = false, onDateChange, ...props }: Props) => {
  const weekDay = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

  const toCamelCase = (date: string) => {
    return date.substr(0, 1).toUpperCase() + date.substr(1)
  }

  const change = (val: Date | Date[]) => {
    onDateChange && onDateChange(val)
  }

  return (
    <S.Wrapper {...props}>
      <CalendarComponent
        showNeighboringMonth={false}
        defaultValue={new Date()}
        calendarType="US"
        formatShortWeekday={(_, date) => weekDay[date.getDay()]}
        formatMonthYear={(_, date) => {
          return toCamelCase(date.toLocaleString('pt-BR', { month: 'long' }))
        }}
        formatMonth={(_, date) => {
          return toCamelCase(date.toLocaleString('pt-BR', { month: 'long' }))
        }}
        next2Label={null}
        prev2Label={null}
        nextLabel={<RightArrow />}
        prevLabel={<LeftArrow />}
        selectRange={range}
        onChange={(val: Date | Date[]) => change(val)}
        {...props}
      />
    </S.Wrapper>
  )
}

export default Calendar
