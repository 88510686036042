import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .react-calendar {
      background: #f9f9f9;
      border-radius: 8px 8px 0px 0px;
      width: 100%;
      border: none;
      font-family: ${theme.font.family} !important;

      button {
        position: relative;
        height: 60px;
      }

      & .react-calendar__tile abbr,
      .react-calendar__tile abbr {
        background-color: transparent !important;
        z-index: 1;
      }

      & .react-calendar__tile--rangeStart abbr,
      .react-calendar__tile--rangeEnd abbr {
        background-color: ${theme.colors.primary} !important;
      }

      & .react-calendar__tile--range {
        position: relative;
      }

      & .react-calendar__tile--range:before {
        content: '';
        position: absolute;
        background: rgba(18, 95, 28, 0.3) !important;
        height: 68%;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(-50%);
      }

      & .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd {
        &:before {
          width: 50%;
        }
      }

      & .react-calendar__tile--rangeStart {
        &:before {
          left: 50%;
        }
      }

      & .react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
        &:before {
          display: none;
        }
      }

      &__navigation__label__labelText {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: ${theme.colors.gray};
        font-family: ${theme.font.family} !important;
      }

      &__month-view__weekdays {
        background-color: ${theme.colors.white};
        border-radius: ${theme.border.radius.small};

        abbr[title] {
          text-decoration: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #0a3256;
        }
      }

      &__tile {
        padding: 0;

        &--now {
          background: none;
        }
      }
    }

    .react-calendar__tile--active:enabled {
      background: none;
      color: white;
      abbr {
        background-color: #5d86da;
      }
    }

    .react-calendar__month-view__days__day {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #0a3256;
      height: 40px;
      font-weight: 500;
      abbr {
        display: block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 100%;
        font-family: ${theme.font.family} !important;
      }
    }

    .react-calendar__tile {
      font-family: ${theme.font.family} !important;
    }
  `}
`

export const RightArrow = styled.div`
  border: 1.5px solid #4a4a4a;
  transform: rotate(-90deg);
  width: 14px;
  height: 7px;
`
