import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteAddFavorite implements AddFavorite {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  add(params: AddFavorite.Params): Promise<AddFavorite.Model> {
    return this.surgicalOrderRepository.addFavorite(params)
  }
}
